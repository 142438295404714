import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import CurveTop from "../components/svg/curveTop";
import ArrowDown from "../components/svg/arrowDown";
import scrollTo from "gatsby-plugin-smoothscroll";

const CareersPage = () => (

  	<Layout>

      <Seo 
         title="Media Careers" 
         description="Looking for your next role in media or advertising consulting in Sydney? We offer data analysis and media strategy review jobs as well as media coaching jobs in Sydney."
      />

      <section role="main" className="bg">
         <div className="container">
            <div className="row">
               <div className="sub-hero-content sub-hero">
                  <div className="sub-hero-text full">
                     <h1
                        data-sal="slide-up" 
                        data-sal-delay="200"
                        data-sal-duration="500"
                     >Looking for a <span className="blue">Mad career</span> with plenty of variety?</h1>
                     <div
                        data-sal="slide-up" 
                        data-sal-delay="400"
                        data-sal-duration="500"
                     >
                        <p>If you want to work somewhere you’ll never stop learning, alongside some of the most respected, experienced and crazy <Link to="/about">media experts</Link>, then write to us and tell us why.</p>
                        <p>You could be involved in anything ... from data analysis and strategy reviews, to coaching workshops and client presentations.</p>
                     </div>
                     <div
                        className="btn-row"
                        data-sal="slide-up"
                        data-sal-delay="600"
                        data-sal-duration="500"
                     >
                        <button
                           onClick={() => scrollTo("#anchor")}
                           className="btn-scroll"
                        >
                           <ArrowDown />
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>

      <div className="curve-btm">
      <CurveTop />
    </div>

    <section className="section-pad" id="anchor">
      <div className="container">
        <div className="row">
          <form method="post" action={process.env.GATSBY_CONTACT_URL}>
            <div className="contact-row">
              <div className="contact-2-col">
                <div className="form-item">
                  <input type="text" name="name" id="usersname" required />
                  <label htmlFor="usersname" className="form-label">
                    Full name
                  </label>
                </div>
              </div>
              <div className="contact-2-col">
                <div className="form-item">
                  <input type="text" name="phone" id="phone" required />
                  <label htmlFor="phone" className="form-label">
                    Phone number
                  </label>
                </div>
              </div>
            </div>

            <div className="contact-row">
              <div className="contact-full-col">
                <div className="form-item">
                  <input type="email" name="email" id="email" required />
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                </div>
              </div>
            </div>

            <div className="contact-row">
              <div className="contact-full-col">
                <div className="form-item">
                  <textarea required name="message" id="help" rows="4" />
                  <label htmlFor="help" className="form-label">
                    Tell us why you would like to work with us
                  </label>
                </div>
                <input
                  type="text"
                  name="_gotcha"
                  style={{ display: "none" }}
                />
              </div>
            </div>

            <div className="contact-row">
              <button type="submit" className="btn btn-lg">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>

  </Layout>
)

export default CareersPage