import React from "react"

const ArrowDown = () => (
    <svg width="21" height="43" viewBox="0 0 21 43" fill="none" xmlns="http://www.w3.org/2000/svg">
        <desc>An arrow icon pointing down</desc>
        <path d="M19.5679 33.1458L10.6095 42.1041L1.65121 33.1458" stroke="black" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.6094 42.1041L10.6094 0.895752" stroke="black" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

export default ArrowDown